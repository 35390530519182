import React from "react";
import MatSwitch from "../../../components/common/Mat/MatSwitch";
import MatSidebarToolbox from "../../../components/common/Mat/MatSidebarToolbox";
// import ordersTableModel from "../ordersTableModel";
import {connect} from "react-redux";
import {Glyphicon} from "react-bootstrap";
import {ButtonIcon, UserNameContainer} from "../../../components/navbarStyles";
import {STATE_LOGGED_IN} from "../../../actions/authActions";
// import { customizerColumnEnabledChanged } from "../actions/filtersActions";
// import OpenLockIcon from "./OpenLockIconSvg";

@connect(state => state.ordersList.filters)
export default class ViewCustomizerSidebar extends React.Component {
    render() {
        const checkedMap = {};
        for (const key of this.props.customizerEnabledColumns) {
            checkedMap[key] = true;
        }
        const elements = [
            {
                title: "Parts Checked chart",
                key: "0"
            },
            {
                title: "NOK chart",
                key: "1"
            },
            {
                title: "Reworked chart",
                key: "2"
            },
            {
                title: "Total table",
                key: "3"
            },
            {
                title: "Hours table",
                key: "4"
            },
            {
                title: "Divided table",
                key: "5"
            },
            {
                title: "Detailed table",
                key: "6"
            },
        ]
        return (
            <MatSidebarToolbox>
                <div>CUSTOMIZER</div>
                <div>
                    <sub>Customize and preview in real time.</sub>
                </div>
                <hr/>
                <div>
                    {/*{ordersTableModel.columns*/}
                    {/*    .filter(col => col.customizerSwitch)*/}
                    {elements.map(col => (
                        <MatSwitch
                            key={col.key}
                            label={col.title}
                            fullWidth
                            checked={!!checkedMap[col.key]}
                            onChange={ev => {
                            }
                                // this.props.dispatch(customizerColumnEnabledChanged(col.key, ev.target.checked))
                            }
                        />
                    ))}
                </div>
                <ButtonIcon>
                    <Glyphicon glyph="lock" />
                </ButtonIcon>
                {/*<ButtonIcon>*/}
                {/*    <OpenLockIcon />*/}
                {/*</ButtonIcon>*/}
            </MatSidebarToolbox>
        );
    }
}
