import React, { useState } from "react";
import styled, { css } from "styled-components";
import StyledIcon from "../StyledIcon";
import OutsideClickHandler from "react-outside-click-handler";

const SidebarTrigger = styled.div`
    position: fixed;
    top: 25%;
    right: 0;
    width: 54px;
    height: 50px;

    text-align: center;
    line-height: 50px;
    cursor: pointer;

    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);

    background: #fafafa;

    transition: 500ms;
    transition-timing-function: ease-in-out;
    ${props =>
        props.expanded &&
        css`
            right: 320px;
        `}
`;

const SidebarWrapper = styled.div`
    z-index: 100000;
    position: fixed;
    right: -325px;
    top: 0;
    height: 100vh;
    width: 320px;
    background: #fafafa;
    box-shadow: none;
    padding: 15px 45px;
    transition: 500ms;
    transition-timing-function: ease-in-out;
    overflow-y: scroll;
    ${props =>
        props.expanded &&
        css`
            right: 0;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
        `}
`;

const CloseButton = styled.div`
    position: relative;
    float: right;
    height: 33px;
    width: 33px;
    margin-top: 10px;
    cursor: pointer;
    &:before,
    &:after {
        position: absolute;
        left: 20px;
        content: " ";
        height: 15px;
        width: 2px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

const Clearfix = styled.div`
    clear: both;
`;

export default ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div>
            <OutsideClickHandler onOutsideClick={() => setIsExpanded(false)}>
                <SidebarTrigger onClick={() => setIsExpanded(!isExpanded)} expanded={isExpanded}>
                    <StyledIcon glyph="cog" />
                </SidebarTrigger>
                <SidebarWrapper expanded={isExpanded}>
                    <CloseButton onClick={() => setIsExpanded(false)} />
                    {children}
                    <Clearfix />
                </SidebarWrapper>
            </OutsideClickHandler>
        </div>
    );
};
