import React from "react";
import styled, { css } from "styled-components";

const SwitchWrapper = styled.label`
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 1.5;
    ${props =>
        props.fullWidth &&
        css`
            display: block;
            margin-top: 17px;
            margin-bottom: 17px;
        `}
`;

const Label = styled.span`
    display: inline-block;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;

    &:before {
        content: "";
        float: right;
        display: inline-block;
        margin: 5px 0 5px 10px;
        border-radius: 7px;
        width: 36px;
        height: 14px;
        background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
        vertical-align: top;
        transition: background-color 0.2s, opacity 0.2s;
    }
    &:after {
        content: "";
        position: absolute;
        top: 4px;
        right: 16px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        transition: background-color 0.2s, transform 0.2s;
    }
`;

const SwitchInput = styled.input`
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    right: 6px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;

    display: none;
    &:checked + ${Label}:before {
        background-color: #66bb6a99;
    }
    &:checked + ${Label}:after {
        background-color: #66bb6a;
        transform: translateX(16px);
    }
`;

export default ({ label, fullWidth, ...props }) => (
    <SwitchWrapper fullWidth={fullWidth}>
        <SwitchInput type="checkbox" {...props} />
        <Label>{label}</Label>
    </SwitchWrapper>
);
